import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
  render() {
    let tab1 = 'Main skills',
      tab2 = 'Experience',
      tab3 = 'Education & Certification';

    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <strong>Javascript</strong>
                        </li>
                        <li>
                          <strong>Typescript </strong>
                        </li>
                        <li>
                          <strong>React </strong>
                        </li>
                        <li>
                          <strong>Firebase </strong>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <p>
                            <strong>
                              Software Engineer
                              <span> - Rummo</span>
                            </strong>
                            <br />
                            March 2021 - May 2022
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              Frontend Engineer<span> - GetGround</span>
                            </strong>{' '}
                            <br />
                            May 2022 - April 2023
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              Frontend Engineer<span> - Probely</span>
                            </strong>{' '}
                            <br />
                            April 2023 - Current
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <p>
                            <strong>
                              BSc in Business Administration
                              <span> - ISCTE, Lisbon</span>
                            </strong>
                            <br />
                            2014 - 2017
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              Ironhack Bootcamp
                              <span> - Lisbon</span>
                            </strong>
                            <br />
                            2020
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
