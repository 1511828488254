import React, { Component } from 'react';

const PortfolioListContent = [
  {
    image: 'image-1',
    category: '',
    title: 'Check the weather',
    link: 'https://nice-weather-uh.netlify.app/',
  },
  {
    image: 'image-2',
    category: '',
    title: 'Look for movies',
    link: 'https://see-you-at-the-movies.netlify.app/',
  },
  {
    image: 'image-3',
    category: '',
    title: 'Play a drinking game with your friends',
    link: 'https://horse-race-game-b133f.web.app/start',
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail ${value.image}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    <a href={value.link}>{value.title}</a>
                  </h4>
                </div>
              </div>
              <a className="link-overlay" href={value.link}>
                {' '}
              </a>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
